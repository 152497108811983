@import '~react-image-gallery/styles/css/image-gallery.css';

@media only screen and (min-device-width: 1080px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 80px;
    height: 80px;
  }
}
/* .image-gallery-image {
  width: 100%;
  height: 350px;
} */
